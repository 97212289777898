import React, { useState, useEffect, useRef } from 'react';
import { 
  getAuth, 
  signInWithPopup, 
  GoogleAuthProvider, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut 
} from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import App from '../TcgApp/js/App';
import '../css/auth.css';
import ParticlesBg from './ParticlesBg.js';

const Auth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activePopup, setActivePopup] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signupCode, setSignupCode] = useState('');
  const [error, setError] = useState('');
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const authRef = useRef(null);

  const auth = getAuth();
  const firestore = getFirestore();
  const googleProvider = new GoogleAuthProvider();

  const checkUserEmailExists = async (email) => {
    try {
      const docRef = doc(firestore, 'users', email);
      const docSnap = await getDoc(docRef);
      return docSnap.exists();
    } catch (error) {
      console.error('Error checking email:', error);
      return false;
    }
  };

  // First verification point: Auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log('Auth State Changed - User:', {
          email: user.email,
          uid: user.uid,
          isRegistering: isRegistering
        });
        
        // Add a small delay to ensure Firebase Auth is fully initialized
        await new Promise(resolve => setTimeout(resolve, 100));
        
        const emailExists = await checkUserEmailExists(user.email);
        console.log('Email exists check:', emailExists);
        
        if (emailExists) {
          setUser(user);
          setIsVerified(true);
          setIsRegistering(false);  // Reset registration flag if already exists
          console.log('Existing user verified');
        } else if (isRegistering) {
          console.log('Attempting to create new user in database');
          // We are authenticated but not verified - this is our window to write to DB
          try {
            // First set the user
            setUser(user);
            const email = auth.currentUser.email;

            if (email === user.email) {
              console.log("Emails match; attempting write... " + user.email);
              // Proceed with Firestore write
            } else {
              console.error("Emails do not match; " + auth.currentUser.email + " " + user.email);
            }
            // Create a reference to the document
            const userRef = doc(firestore, 'users', user.email);
            // Wait a bit more to ensure auth is propagated
            await new Promise(resolve => setTimeout(resolve, 100));
            await setDoc(userRef, {
                  email: user.email,
                  createdAt: new Date().toISOString()
                });
            // Now try to write with retries
            let attempts = 0;
            const maxAttempts = 3;           
            await new Promise(resolve => setTimeout(resolve, 500));
            // After successful write
            setIsVerified(true);
            setIsRegistering(false);
            console.log('New user successfully registered and verified');
          } catch (error) {
            console.error('Final error adding user to database:', {
              code: error.code,
              message: error.message,
              fullError: error
            });
            await auth.signOut();
            setUser(null);
            setIsVerified(false);
            setIsRegistering(false);
            setError('Failed to create user account. Please try again.');
          }
        } else {
          console.log('Not registering and no DB entry - signing out');
          await auth.signOut();
          setUser(null);
          setIsVerified(false);
          setError('Account not found in database or disabled. Please contact administrator.');
        }
      } else {
        console.log('No user in auth state');
        setUser(null);
        setIsVerified(false);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth, isRegistering]);

  const validateSignupCode = async () => {
    try {
      const docRef = doc(firestore, 'registerAuth', signupCode);
      const docSnap = await getDoc(docRef);
      return docSnap.exists();
    } catch (error) {
      console.error('Error validating signup code:', error);
      return false;
    }
  };

  const signInWithGoogle = async () => {
    setError('');
    setIsGoogleLoading(true);
    setIsVerified(false);
    setIsRegistering(false); // Ensure we're not in registering mode
    
    try {
      await signInWithPopup(auth, googleProvider);
      // Auth state listener will handle the rest
    } catch (error) {
      console.error('Google sign-in error:', error);
      setError(error.message || 'Error signing in with Google');
      setIsVerified(false);
    } finally {
      setIsGoogleLoading(false);
    }
  };

  const signUpWithGoogle = async () => {
    setError('');
    setIsGoogleLoading(true);
    setIsVerified(false);
    
    try {
      if (!await validateSignupCode()) {
        setError('Invalid signup code');
        return;
      }

      setIsRegistering(true); // Set registering flag BEFORE authentication
      await signInWithPopup(auth, googleProvider);
      // useEffect will handle the database writing when it sees isRegistering is true
    } catch (error) {
      console.error('Google registration error:', error);
      setError(error.message || 'Error registering with Google');
      setIsVerified(false);
      setIsRegistering(false);
    } finally {
      setIsGoogleLoading(false);
    }
  };

  const signInWithEmail = async () => {
    setError('');
    setIsEmailLoading(true);
    setIsVerified(false);
    setIsRegistering(false); // Ensure we're not in registering mode
    
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setActivePopup(null);
      // Auth state listener will handle the rest
    } catch (error) {
      console.error('Email sign-in error:', error);
      setError(error.message || 'Error signing in with email');
      setIsVerified(false);
    } finally {
      setIsEmailLoading(false);
    }
  };

  const registerWithEmail = async () => {
    setError('');
    setIsEmailLoading(true);
    setIsVerified(false);
    
    try {
      if (!await validateSignupCode()) {
        setError('Invalid signup code');
        return;
      }

      setIsRegistering(true); // Set registering flag BEFORE authentication
      await createUserWithEmailAndPassword(auth, email, password);
      setActivePopup(null);
      // useEffect will handle the database writing when it sees isRegistering is true
      
    } catch (error) {
      console.error('Email registration error:', error);
      if (error.code === 'auth/weak-password') {
        setError('Password should be at least 6 characters');
      } else if (error.code === 'auth/invalid-email') {
        setError('Invalid email address');
      } else if (error.code === 'auth/email-already-in-use') {
        setError('This email is already in use. Please sign in instead.');
      } else {
        setError(error.message || 'Error creating account');
      }
      setIsVerified(false);
      setIsRegistering(false);
    } finally {
      setIsEmailLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  // Only render App if user is verified
  if (user && isVerified) {
    return <App />;
  }

  return (
    <div className="auth-page" ref={authRef}>
      <ParticlesBg 
        backgroundColors="linear-gradient(135deg, #3990e6 0%, #149f7c 100%)"
        density={150}
        dotColor="#5cbdaa"
        lineColor="#5cbdaa"
        particleRadius={3}
        lineWidth={0.7}
        proximity={150}
        maxSpeedX={0.4}
        maxSpeedY={0.4}
      />
      <div className="login-container">
        <h1>Cards Exporter V0.1</h1>
        {error && <div className="error-message">{error}</div>}
        <div className="button-group">
          <button onClick={() => setActivePopup('email')} className="auth-button email">
            <i className="fa-solid fa-envelope"></i>
            Continue with Email
          </button>
          <button 
            onClick={signInWithGoogle} 
            className="auth-button google"
            disabled={isGoogleLoading}
          >
            <i className="fa-brands fa-google"></i>
            {isGoogleLoading ? 'Connecting...' : 'Continue with Google'}
          </button>
          <button onClick={() => setActivePopup('create')} className="auth-button">
            <i className="fa-solid fa-user-plus"></i>
            Create Account
          </button>
        </div>
      </div>

      {activePopup && (
        <div 
          className="popup-overlay" 
          onClick={() => {
            setActivePopup(null);
            setError('');
          }}
        >
          <div className="popup-content" onClick={e => e.stopPropagation()}>
            {error && <div className="error-login-message">{error}</div>}
            
            {activePopup === 'email' && (
              <form className="auth-form" onSubmit={(e) => { e.preventDefault(); signInWithEmail(); }}>
                <h2>Sign in with Email</h2>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="auth-input"
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="auth-input"
                  required
                />
                <button 
                  type="button" 
                  className="submit-button sign-in-btn" 
                  onClick={signInWithEmail}
                  disabled={isEmailLoading}
                >
                  {isEmailLoading ? 'Signing in...' : 'Sign In'}
                </button>
              </form>
            )}

            {activePopup === 'create' && (
              <form className="auth-form" onSubmit={(e) => { e.preventDefault(); registerWithEmail(); }}>
                <h2>Create Account</h2>
                <p className="small-text">Signup Code required. Please contact administrator.</p>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="auth-input"
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="auth-input"
                  required
                />

                <button 
                  type="button" 
                  className="submit-button sign-in-btn" 
                  onClick={registerWithEmail}
                  disabled={isEmailLoading}
                >
                  {isEmailLoading ? 'Registering...' : <><i className="fa-solid fa-envelope"></i> Register</>}
                </button>
                <button 
                  onClick={signUpWithGoogle}
                  className="auth-button google"
                  disabled={isGoogleLoading}
                >
                  {isGoogleLoading ? 'Connecting...' : <><i className="fa-brands fa-google"></i> Register with Google</>}
                </button>
                <input
                  type="text"
                  placeholder="Signup Code"
                  value={signupCode}
                  onChange={(e) => setSignupCode(e.target.value)}
                  className="auth-input"
                  required
                />
              </form>
            )}

            <button 
              className="close-button"
              onClick={() => {
                setActivePopup(null);
                setError('');
              }}
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Auth;
import React, { useState, useEffect } from 'react';

const Table = ({ setsData }) => {
    const [sortedData, setSortedData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'releaseDate', direction: 'desc' });

    // Function to handle sorting by column
    const sortByColumn = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Sorting logic
    useEffect(() => {
        const sorted = [...setsData].sort((a, b) => {
            if (sortConfig.key === 'releaseDate') {
                const dateA = new Date(a.releaseDate);
                const dateB = new Date(b.releaseDate);
                return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
            } else {
                const valA = a[sortConfig.key];
                const valB = b[sortConfig.key];
                if (valA < valB) return sortConfig.direction === 'asc' ? -1 : 1;
                if (valA > valB) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            }
        });
        setSortedData(sorted);
    }, [setsData, sortConfig]);

    // Get sorting symbol
    const getSortSymbol = (key) => {
        if (sortConfig.key !== key) return '';
        return sortConfig.direction === 'asc' ? '▲' : '▼';
    };

    // Get header class for styling
    const getHeaderClass = (key) => {
        if (sortConfig.key !== key) return '';
        return sortConfig.direction === 'asc' ? 'sort-asc' : 'sort-desc';
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Select</th>
                        <th onClick={() => sortByColumn('name')} className={getHeaderClass('name')}>
                            Name {getSortSymbol('name')}
                        </th>
                        <th onClick={() => sortByColumn('code')} className={getHeaderClass('code')}>
                            Set Code {getSortSymbol('code')}
                        </th>
                        <th onClick={() => sortByColumn('releaseDate')} className={getHeaderClass('releaseDate')}>
                            Release Date {getSortSymbol('releaseDate')}
                        </th>
                        <th onClick={() => sortByColumn('lastUpdated')} className={getHeaderClass('lastUpdated')}>
                            Latest Update {getSortSymbol('lastUpdated')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((set, index) => (
                        <tr key={index}>
                            <td>
                                <input type="checkbox" />
                            </td>
                            <td>{set.name}</td>
                            <td>{set.code}</td>
                            <td>{set.releaseDate}</td>
                            <td>{set.lastUpdated}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;

import { useState, useEffect } from 'react';
import Papa from 'papaparse';

export const useFetchSetsData = (refreshTrigger) => {
  const [allSetsData, setAllSetsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCSVData = async () => {
      setIsLoading(true);
      setError(null);
      
      const CORS_PROXY = ''; // disabled in prod https://cors-anywhere.herokuapp.com/ 
      const targetUrl = 'https://tcgprojectstorage.blob.core.windows.net/csvdata/2024-10-06_SetExports.csv';

      try {
        const response = await fetch(CORS_PROXY + targetUrl, {
          cache: 'no-cache' // This ensures we don't get cached data
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const reader = response.body.getReader();
        const result = await reader.read();
        const decoder = new TextDecoder('utf-8');
        const csvData = decoder.decode(result.value);
        
        Papa.parse(csvData, {
          header: true,
          complete: (result) => {
            const parsedData = result.data;
            const groupedData = parsedData.reduce((acc, set) => {
              const game = set.Game;
              if (!acc[game]) {
                acc[game] = [];
              }
              acc[game].push({
                name: set.Name,
                code: set.SetAbbreviation,
                releaseDate: set.ReleaseDate,
                lastUpdated: set.LastUpdated,
              });
              return acc;
            }, {});
            
            setAllSetsData(groupedData);
            setIsLoading(false);
          },
          error: (error) => {
            setError(error.message);
            setIsLoading(false);
          }
        });
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchCSVData();
  }, [refreshTrigger]);

  return { allSetsData, isLoading, error };
};
// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import './assets/css/index.css';
import Auth from './assets/js/Auth';
import reportWebVitals from './reportWebVitals';

const firebaseConfig = {
  apiKey: "AIzaSyBkgojWdGU3P-Dg3tPrtY1rzljXF4g3vLE",
  authDomain: "tcgproject-ed393.firebaseapp.com",
  projectId: "tcgproject-ed393",
  storageBucket: "tcgproject-ed393.appspot.com",
  messagingSenderId: "1069269811024",
  appId: "1:1069269811024:web:14b68749087ce8c683f655"
};

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth />
  </React.StrictMode>
);

reportWebVitals();
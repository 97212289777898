// hooks/useFetchRaritiesData.js
import { useState, useEffect } from 'react';
import Papa from 'papaparse';

export const useFetchRaritiesData = () => {
  const [rarities, setRarities] = useState({});

  useEffect(() => {
    const fetchRarityReport = async () => {
      const CORS_PROXY = ''; // disabled in prod https://cors-anywhere.herokuapp.com/
      const targetUrl = 'https://tcgprojectstorage.blob.core.windows.net/csvdata/RarityReport.csv';

      const response = await fetch(CORS_PROXY + targetUrl);
      const reader = response.body.getReader();
      const result = await reader.read(); // raw binary data
      const decoder = new TextDecoder('utf-8');
      const csvData = decoder.decode(result.value);

      // Parse CSV data
      Papa.parse(csvData, {
        header: true,
        complete: (result) => {
          const parsedData = result.data;

          // Group rarities by game
          const raritiesByGame = parsedData.reduce((acc, row) => {
            const game = row.Game;
            const rarity = row.Rarity;
            if (!acc[game]) {
              acc[game] = [];
            }
            acc[game].push(rarity);
            return acc;
          }, {});

          setRarities(raritiesByGame);
        },
        error: (error) => {
          console.error('Error parsing rarity CSV:', error);
        }
      });
    };

    fetchRarityReport();
  }, []);

  return rarities;
};

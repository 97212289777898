import Papa from 'papaparse';

const BLOB_STORAGE_BASE_URL = 'https://tcgprojectstorage.blob.core.windows.net/csvdata/LatestExports/';
const IMAGE_BASE_URL = 'https://tcgprojectstorage.blob.core.windows.net/images/';
const CORS_PROXY = ''; // Add the CORS proxy here https://cors-anywhere.herokuapp.com/

export const exportShopifyCSV = async (selectedSets, minimumPrice, rarityPreferences, priceMultiplier) => {
  try {
    const allData = await fetchAllSetData(selectedSets);
    const processedData = processData(allData, minimumPrice, rarityPreferences, priceMultiplier);
    const normalCSV = generateNormalCSV(processedData);
    const shopifyCSV = generateShopifyCSV(processedData, minimumPrice, priceMultiplier);
    return { normalCSV, shopifyCSV };
  } catch (error) {
    console.error('Error exporting Shopify CSV:', error);
    throw error;
  }
};

const fetchAllSetData = async (selectedSets) => {
  const allData = [];
  for (const set of selectedSets) {
    const url = `${BLOB_STORAGE_BASE_URL}${set.code}_cards.csv`;
    const proxyUrl = CORS_PROXY + url; // Add proxy before the URL

    const response = await fetch(proxyUrl);
    const text = await response.text();
    const { data } = Papa.parse(text, { header: true });
    allData.push(...data);
  }
  return allData;
};

const processData = (data, minimumPrice, rarityPreferences, priceMultiplier = 1) => {
  return data.filter(item => {
    // Check if any rarities are selected for the current game
    const selectedRarities = rarityPreferences[item.Game] || [];
    console.log(selectedRarities);
    // If no rarities are selected, keep all cards
    // If rarities are selected, only keep cards with matching rarities
    return selectedRarities.length === 0 || selectedRarities.includes(item.Rarity);
  }).map(item => {
    const originalPrice = parseFloat(item.MarketPrice);
    const multipliedPrice = originalPrice * priceMultiplier;
    const adjustedPrice = multipliedPrice < minimumPrice ? minimumPrice : multipliedPrice;
    return {
      ...item,
      MarketPrice: adjustedPrice.toFixed(2)
    };
  });
};

const generateNormalCSV = (data) => {
  return Papa.unparse(data);
};

const generateShopifyCSV = (data, minimumPrice, priceMultiplier) => {
  const shopifyData = data
    .filter(item => item.InternalID && item.ProductName && item.MarketPrice && item.VariantID)
    .map(item => ({
      Handle: item.InternalID,
      Title: item.ProductName,
      'Body (HTML)': `<p>${item.ProductName} - ${item.SetAbbreviation}</p>`,
      Vendor: item.Game,
      'Product Category': 'Arts & Entertainment > Hobbies & Creative Arts > Collectibles > Collectible Trading Cards > Non-Sports Trading Cards',
      Type: 'Trading Card',
      Tags: `${item.Game}, Set:${item.SetAbbreviation}, ${item.Rarity}`,
      'Custom Collections': item.SetAbbreviation,
      Published: 'FALSE',
      'Option1 Name': 'Condition',
      'Option1 Value': item.Condition,
      'Option2 Name': 'Printing',
      'Option2 Value': item.Printing,
      'Variant SKU': `${item.VariantID}`,
      'Variant Price': item.MarketPrice,
      'Variant Inventory Policy': 'deny',
      'Variant Inventory Tracker': 'shopify',
      'Variant Fulfillment Service': 'manual',
      'Variant Requires Shipping': 'TRUE',
      'Variant Taxable': 'TRUE',
      'Image Src': `${IMAGE_BASE_URL}${item.ProductId}.jpg`,
      Status: 'active'
    }));

  return Papa.unparse(shopifyData);
};

import React, { useState } from 'react';
import Sidebar from './components/Sidebar';
import ActionButtons from './components/ActionButtons';
import Table from './components/Table';
import RarityToggles from './components/RarityToggles';
import PriceFilter from './components/PriceFilter';
import { useFetchSetsData } from './hooks/useFetchSetsData';
import { useFetchRaritiesData } from './hooks/useFetchRaritiesData';
import { useRarityPreferences } from './hooks/useRarityPreferences';
import '../css/app.css';
import { getAuth, signOut } from 'firebase/auth';

const App = () => {
  const [selectedGame, setSelectedGame] = useState('YuGiOh');
  const [minimumPrice, setMinimumPrice] = useState('');
  const [priceMultiplier, setPriceMultiplier] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const { allSetsData, isLoading, error } = useFetchSetsData(refreshTrigger);
  const rarities = useFetchRaritiesData();
  const [rarityPreferences, handleToggleRarity] = useRarityPreferences();
  const auth = getAuth();

  const handlePriceChange = (e) => {
    setMinimumPrice(e.target.value);
  };

  const handleMultiplierChange = (e) => {
    setPriceMultiplier(Math.max(1, parseFloat(e.target.value) || 1));
  };

  const refreshSets = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  const handleSelectAll = () => {
    const visibleCheckboxes = document.querySelectorAll('.visible .table-container input[type="checkbox"]');
    visibleCheckboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });
  };
  
  const handleUnselectAll = () => {
    const visibleCheckboxes = document.querySelectorAll('.visible .table-container input[type="checkbox"]');
    visibleCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };
  
  const getSelectedSets = () => {
    const selectedSets = [];
    const rows = document.querySelectorAll('.visible .table-container tbody tr');
    
    rows.forEach(row => {
      const checkbox = row.querySelector('input[type="checkbox"]');
      if (checkbox && checkbox.checked) {
        // Get the set code from the corresponding row
        const setCode = row.cells[2].textContent.trim();  // Assuming the set code is in the 3rd column
        selectedSets.push({ code: setCode });
      }
    });
    
    return selectedSets;
  };
  
  // Function to handle checkbox selection directly from the checkbox element
  const handleSetSelection = (event, set) => {
    // You can get the state of the checkbox using event.target.checked
    const isSelected = event.target.checked;
    // You can add any logic if needed when a checkbox is selected or unselected
    console.log(`${set.name} is now ${isSelected ? 'selected' : 'unselected'}`);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // The Auth component will handle the redirect after logout
      // because it's listening to auth state changes
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="app">
      <button 
        onClick={handleLogout}
        className="logout-button"
      >
        Logout
      </button>
      <Sidebar 
        selectedGame={selectedGame} 
        onGameSelect={setSelectedGame} 
      />
      <div className="main-content">
        {error && <div className="error-message">Error: {error}</div>}
        {isLoading ? (
          <div className="loading-spinner">Loading...</div>
        ) : (
          <>
            {Object.keys(allSetsData).map((game) => (
              <div
                key={game}
                className={selectedGame === game ? "visible" : "hidden"}
              >
                <Table 
                  setsData={allSetsData[game]} 
                  onSetSelection={handleSetSelection} // Pass the handler to Table
                />
                {rarities[game] && (
                  <RarityToggles
                    game={game}
                    rarities={rarities[game]}
                    raritySelections={rarityPreferences[game] || []}
                    onToggleRarity={(rarity) => handleToggleRarity(game, rarity)}
                  />
                )}
              </div>
            ))}
            <PriceFilter
              minimumPrice={minimumPrice}
              handlePriceChange={handlePriceChange}
              priceMultiplier={priceMultiplier}
              handleMultiplierChange={handleMultiplierChange}
            />
          </>
        )}
      </div>
      <ActionButtons
        onRefreshSets={refreshSets}
        isLoading={isLoading}
        onSelectAll={handleSelectAll}
        onUnselectAll={handleUnselectAll}
        minimumPrice={parseFloat(minimumPrice) || 0}
        priceMultiplier={priceMultiplier}
        rarityPreferences={rarityPreferences}
        getSelectedSets={getSelectedSets}
      />
    </div>
  );
};

export default App;

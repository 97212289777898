// components/PriceFilter.js
import React from 'react';

const PriceFilter = ({ minimumPrice, handlePriceChange, priceMultiplier, handleMultiplierChange }) => (
  <div className="price-filter">
    <label>
      Minimum Price:
      <input
        type="number"
        value={minimumPrice}
        onChange={handlePriceChange}
        placeholder="Enter minimum price"
      />
    </label>
    <label>
      Price Multiplier:
      <input
        type="number"
        value={priceMultiplier}
        onChange={handleMultiplierChange}
        min="1"
        step="0.1"
        placeholder="Enter price multiplier"
      />
    </label>
  </div>
);

export default PriceFilter;

import React from 'react';

const RarityToggles = ({ game, rarities, raritySelections, onToggleRarity }) => {
    return (
        <div className="rarity-toggles">
            <h4>{game} Rarities:</h4>
            {rarities.map((rarity, index) => (
                <label key={index}>
                    <input
                        type="checkbox"
                        checked={raritySelections.includes(rarity)}
                        onChange={() => onToggleRarity(rarity)}
                    />
                    {rarity}
                </label>
            ))}
        </div>
    );
};

export default RarityToggles;

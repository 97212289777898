import React from 'react';
import { exportShopifyCSV } from '../utils/shopifyExports';

const ActionButtons = ({ 
  onSelectAll, 
  onUnselectAll, 
  onRefreshSets, 
  isLoading, 
  minimumPrice,
  rarityPreferences,
  getSelectedSets,
  priceMultiplier  // Make sure this prop is being received
}) => {
  const handleExport = async () => {
    try {
      const selectedSets = getSelectedSets();
      const { normalCSV, shopifyCSV } = await exportShopifyCSV(
        selectedSets, 
        parseFloat(minimumPrice) || 0,  // Parse minimumPrice
        rarityPreferences,
        parseFloat(priceMultiplier) || 1  // Parse priceMultiplier and default to 1 if it's NaN
      );
      
      // Create and download Normal CSV
      const normalBlob = new Blob([normalCSV], { type: 'text/csv;charset=utf-8;' });
      const normalLink = document.createElement("a");
      if (normalLink.download !== undefined) {
        const normalUrl = URL.createObjectURL(normalBlob);
        normalLink.setAttribute("href", normalUrl);
        normalLink.setAttribute("download", "normal_export.csv");
        normalLink.style.visibility = 'hidden';
        document.body.appendChild(normalLink);
        normalLink.click();
        document.body.removeChild(normalLink);
      }

      // Create and download Shopify CSV
      const shopifyBlob = new Blob([shopifyCSV], { type: 'text/csv;charset=utf-8;' });
      const shopifyLink = document.createElement("a");
      if (shopifyLink.download !== undefined) {
        const shopifyUrl = URL.createObjectURL(shopifyBlob);
        shopifyLink.setAttribute("href", shopifyUrl);
        shopifyLink.setAttribute("download", "shopify_export.csv");
        shopifyLink.style.visibility = 'hidden';
        document.body.appendChild(shopifyLink);
        shopifyLink.click();
        document.body.removeChild(shopifyLink);
      }
    } catch (error) {
      console.error('Error during export:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <div className="sidebar-right">
      <button onClick={onUnselectAll}>Unselect All</button>
      <button onClick={onSelectAll}>Select All</button>
      <button onClick={handleExport}>Export Shopify CSV</button>
      <button 
        className="RefreshButton"
        onClick={onRefreshSets}
        disabled={isLoading}
      >
        {isLoading ? 'Refreshing...' : 'Refresh Sets'}
      </button>
    </div>
  );
};

export default ActionButtons;

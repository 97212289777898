import React from 'react';

const gameMapping = {
    'Yu-Gi-Oh!': 'YuGiOh',
    'Magic: The Gathering': 'Magic',
    'Pokémon': 'Pokemon',
    'One Piece': 'One Piece Card Game',
    'Lorcana': 'Lorcana TCG'
};

const Sidebar = ({ selectedGame, onGameSelect, onRefreshSets, isLoading }) => {
    const games = ['Yu-Gi-Oh!', 'Magic: The Gathering', 'Pokémon', 'One Piece', 'Lorcana'];

    return (
        <div className="sidebar">
            {games.map((game, index) => (
                <button
                    key={index}
                    className={selectedGame === gameMapping[game] ? 'active' : ''}
                    onClick={() => onGameSelect(gameMapping[game])}
                >
                    {game}
                </button>
            ))}
        </div>
    );
};

export default Sidebar;
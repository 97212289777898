// hooks/useRarityPreferences.js
import { useState } from 'react';

export const useRarityPreferences = () => {
  const [rarityPreferences, setRarityPreferences] = useState({});

  const handleToggleRarity = (game, rarity) => {
    const updatedPreferences = { ...rarityPreferences };
    const gamePreferences = updatedPreferences[game] || [];

    // If rarity is already in the preferences, remove it; otherwise, add it
    if (gamePreferences.includes(rarity)) {
      updatedPreferences[game] = gamePreferences.filter(r => r !== rarity);
    } else {
      updatedPreferences[game] = [...gamePreferences, rarity];
    }

    setRarityPreferences(updatedPreferences);
    console.log('UpdatePreferences was called');
  };

  return [rarityPreferences, handleToggleRarity];
};
